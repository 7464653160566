.manual-score-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10%;
    padding: 20px;
  }
  
  .manual-score-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .manual-score-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .save-button {
    padding: 10px 20px;
    background-color: #007bff; /* Primary color */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Table styling */
  .manual-score-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .manual-score-table thead {
    background-color: #f8f9fa;
  }
  
  .manual-score-table th,
  .manual-score-table td {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: center;
    font-size: 16px;
  }
  
  .manual-score-table th {
    font-weight: bold;
    color: #495057;
  }
  
  .manual-score-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .manual-score-table tbody tr:hover {
    background-color: #e9ecef;
  }
  
  .manual-score-table td input[type='number'] {
    width: 80px;
    padding: 6px 8px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    text-align: center;
  }
  
  .manual-score-table td input[type='number']:focus {
    border-color: #80bdff;
    outline: none;
  }
  
  /* Max score styling */
  .max-score {
    display: block;
    font-size: 12px;
    color: #6c757d;
    margin-top: 4px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .manual-score-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .save-button {
      margin-top: 10px;
    }
  
    .manual-score-table th,
    .manual-score-table td {
      font-size: 14px;
      padding: 8px;
    }
  
    .manual-score-table td input[type='number'] {
      width: 60px;
      padding: 4px 6px;
      font-size: 14px;
    }
  }
  