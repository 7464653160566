.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .input-group h1 {
    margin-right: 10px;
    /* You can add any other styles you want for the <h1> element here */
  }
  
  .input-group input {
    width: 50%;
    padding: 6px 12px;
    box-sizing: border-box;
    font-size: 18px;
    /* You can add any other styles for input fields here */
  }
  
  .error-message {
    color: red;
    font-size: 24px;
  }