.main-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }

.container {
    width: 740px;
    max-width: 1500px;
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
    line-height: 1.6;
  }

