
/* The table itself */
#ranking-table, #manual-table {
    width: 100%;  /* Changed to 100% of its container */
    margin: auto;  /* Center the table */
    border-collapse: collapse;  /* Removes cell spacing */
    font-size: 24px;  /* Increased font size */
    margin-top: 2rem;  /* Space from the navbar */
}

/* Table headers */
#ranking-table th, #manual-table th {
background-color: #2C3E50;  /* Darker header color */
color: #ECF0F1;  /* Light font color for header */
font-weight: normal;
}

/* Table cells */
#ranking-table td, #ranking-table th, #manual-table td, #manual-table th{
padding: 20px;  /* Increased padding */
border: 1px solid #AAB7B8;  /* Cell border */
text-align: center;  /* Centering content in cells */
}



@media (max-width: 600px) {
    #ranking-table, #manual-table {
    font-size: 14px;
}
}


.controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa; /* Light background */
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Individual Control Group */
  .control-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Label Styling */
  .control-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  /* Label Text */
  .label-text {
    font-weight: bold;
    margin-right: 10px;
    font-size: 16px;
    color: #333;
  }
  
  /* Select Dropdown Styling */
  .league-select {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    color: #495057;
    transition: border-color 0.2s;
  }
  
  .league-select:focus {
    border-color: #80bdff;
    outline: none;
  }
  
  /* Multiplier Styling */
  .multiplier-label {
    font-weight: bold;
    font-size: 16px;
    color: #333;
    margin-right: 5px;
  }
  
  .multiplier-value {
    font-size: 18px;
    font-weight: bold;
    color: #007bff; /* Accent color */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .controls {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .control-group {
      margin-bottom: 15px;
    }
  }

  .multiplier-input {
    width: 80px;
    padding: 8px 10px;
    font-size: 16px;
    margin-left: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .multiplier-input:focus {
    border-color: #80bdff;
    outline: none;
  }

  .update-questions-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
