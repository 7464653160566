.container {
    text-align: center;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  


h1 {
    color: #333;
    margin-bottom: 30px;
  }
  
.instructions {
    text-align: left;
    margin-bottom: 30px;
  }
  
.instructions h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
.instructions ol {
    padding-left: 20px;
  }
  
.instructions li {
    margin-bottom: 10px;
  }
  
.instructions pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
.instructions ul {
    padding-left: 20px;
    margin-top: 10px;
  }
  
.encrypted-container {
    font-family: 'Courier New', Courier, monospace;
    background-color: #2a1b62; 
    color: #FFFFFF; 
    padding: 20px;
  }
.encrypted {
    word-wrap: break-word;
    background-color: #483D8B; 
    border: 1px solid #7B68EE; 
    padding: 10px;
    margin: 20px 0;
  }
  
textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    resize: vertical;
    margin-bottom: 20px;
  }
  
button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block; /* Make the button a block-level element */
    margin: auto;
  }
  
button:hover {
    background-color: #0056b3;
  }
  
#response {
    text-align: left;
    margin-top: 30px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
#response p {
    margin: 5px 0;
  }
  
#response strong {
    display: inline-block;
    width: 150px;
  }
  
#message {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
#messageContent {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 10px;
  }
