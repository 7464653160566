.navbar {
  background-color: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar a:hover {
  background-color: #111;
}

.logout-button {
  background-color: #af4c4c;
  color: white;
  margin: 0px;
  padding: 12px 18px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
  width: 10%;
  }
  
.logout-button:hover {
    background-color: #6d2121;
    }