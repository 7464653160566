.App {
  font-family: Arial, sans-serif;
  line-height: 1.3;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex; /* Changed from block to flex */
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Centers children vertically */
  min-height: 100vh; /* Minimum height of 100% of the viewport height */
  text-align: center; /* Centers the text */
}


.icon-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.icon-button:hover {
  background-color: #0056b3;
}