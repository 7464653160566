.hidden {
  display: none;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

#question-image {
  width: 80%;
  margin: 0 auto 20px auto;
}

input[type="radio"] {
  margin-bottom: 10px;
}

input[type="text"] {
  width: 700px;
  /* Updated width */
  font-size: 28px;
  /* Updated font-size */
  margin-top: 40px;
}


button[type="submit"] {
  background-color: #007BFF;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056B3;
}



.container {
  width: 740px;
  max-width: 1500px;
  padding: 20px;
  box-sizing: border-box;
}

.login-container {
  width: 100%;
  text-align: center;
}

.login-container h1 {
  font-size: 28px;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 18px;
}

#short_answer {
  /*add padding on top*/
  width: 692px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 16px 32px;
  margin: 16px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  transition: 0.3s;
}

button:hover {
  background-color: #45a049;
}

.main-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-container {
  width: 700px;
  margin: 0 auto;
  user-select: none;
}

.navigate {
  display: flex;
  /* This will align children elements (buttons) in a row */
  justify-content: space-between;
}

#prev-question,
#next-question {
  width: 100px;
  height: 40px;
  margin: 20px 0;
  font-size: 16px;
  padding: 10px;
  /* Adjust as needed */
  line-height: 20px;
  /* Half the height to vertically center text, adjust if you change the height */
  text-align: center;

}

#previous-question {
  float: left;

}

#next-question {
  float: right;
}

#prev-question:disabled,
#next-question:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


#questionNavContainer {
  margin-top: 50px;
  width: 100%;
  overflow-x: auto;
  /* Adds horizontal scrollbar if content overflows */
}

#questionNav {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  table-layout: fixed;
  /* New addition */
}

#questionNav td {
  border: 1px solid rgb(255, 255, 255);
  /* width: 10%; You would set this dynamically based on the number of questions, or leave it out and let 'table-layout: fixed' take care of it. */
  padding: 0;
  margin: 0;
}

#questionNav button {
  width: 100%;
  height: 100%;
  min-height: 40px;
  border: none;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 1% 2%;
}

.normal-font-weight {
  display: block;
  font-weight: normal;
}

.option-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.option-text p,
.option-text div,
.option-text * {
  margin: 0;
  padding: 0;
}

.copy {
  /* button */
  --button-bg: #353434;
  --button-hover-bg: #464646;
  --button-text-color: #CCCCCC;
  --button-hover-text-color: #8bb9fe;
  --button-border-radius: 10px;
  --button-diameter: 36px;
  --button-outline-width: 1px;
  --button-outline-color: rgb(141, 141, 141);
  /* tooltip */
  --tooltip-bg: #f4f3f3;
  --toolptip-border-radius: 4px;
  --tooltip-font-family: Menlo, Roboto Mono, monospace;
  /* 👆 this field should not be empty */
  --tooltip-font-size: 12px;
  /* 👆 this field should not be empty */
  --tootip-text-color: rgb(50, 50, 50);
  --tooltip-padding-x: 7px;
  --tooltip-padding-y: 7px;
  --tooltip-offset: 8px;
  /* --tooltip-transition-duration: 0.3s; */
  /* 👆 if you need a transition, 
  just remove the comment,
  but I didn't like the transition :| */
}

.copy {
  box-sizing: border-box;
  width: var(--button-diameter);
  height: var(--button-diameter);
  border-radius: var(--button-border-radius);
  background-color: var(--button-bg);
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
}

.tooltip {
  position: absolute;
  opacity: 0;
  visibility: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font: var(--tooltip-font-size) var(--tooltip-font-family);
  color: var(--tootip-text-color);
  background: var(--tooltip-bg);
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  border-radius: var(--toolptip-border-radius);
  pointer-events: none;
  transition: all var(--tooltip-transition-duration) cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  content: attr(data-text-initial);
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: calc(var(--tooltip-padding-y) / 2 * -1);
  width: var(--tooltip-padding-y);
  height: var(--tooltip-padding-y);
  background: inherit;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -999;
  pointer-events: none;
}

.button-copy{
  background-color: gray;
}

.button-copy:hover {
  background-color: rgb(68, 68, 68);
}